import React from 'react';
import {Row, Container} from 'react-bootstrap';
import { WalletButton } from '../components';

export const Home = (props: any) => {
  const randoNumber = Math.floor(Math.random() * (501 - 1) + 1);
  const randoBabyUrl = `/babies/baby${randoNumber}.png`
  return (
  // <div className="Page-centered">
  //   <Row className="align-items-center">
  //     <Container>
  //       <div className="">
  //         <img alt="" src={randoBabyUrl} width="20%" height="45%" className="App-logo-spin App-logo d-inline-block align-top" />
  //       </div>
  //     </Container>
  //     <Container style={{paddingTop:"5vh"}}>
  //       <h1>
  //         COMING SOON
  //       </h1>
  //     </Container>
  //   </Row>
  // </div> 
    <div className="MintContainer" style={{paddingTop:"5vh", paddingBottom: "5vh"}}>
      <Container style={{paddingBottom:"5vh"}}>
        <h3> COMING SOON </h3>
        <div className="">
        <img alt="" src={"1tranparent.png"} width="20%" height="45%" className="App-logo-spin App-logo d-inline-block align-top" />
        </div>
      </Container>
      <div className="homePage">
        <WalletButton />
      </div>
    </div>
  )
}