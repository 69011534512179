import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import { NavigationBar } from './components';
import { Home } from './pages'
import 'bootstrap/dist/css/bootstrap.min.css';

export const App = (props: any) => {
  return (
    <div className="App">
      <NavigationBar {...props} />
      <div className="App-body">
        <Router>
            <Switch>
              <Route> <Home web3={props.web3} /></Route>
              <Route  />
            </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;


