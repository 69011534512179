import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import MetaMaskOnboarding from '@metamask/onboarding';
import { isMetamaskInstalled } from '../utilities';

const forwarderOrigin = process.env.PUBLIC_URL;

export const WalletButton = (props: any) => {
    const [ disabled, setDisabled ] = useState(false)
    const [ account, setAccount ] = useState('');
    const metamaskButtonText = () => {
        if (!isMetamaskInstalled()) {
          return 'INSTALL MM';
        } 
        else {
          if (account) return `${account.slice(0, 5)}...${account.slice(-5)}`;
          return 'GET RUGGED';
        }
    };
    const connectWallet = async () => {
        try {
            const { ethereum } = window as any;
            const accounts = await ethereum.request({ method: "eth_requestAccounts"});
            setAccount(accounts[0]);
        } catch (error) {
            console.log(error)
        }
    }
    const metamaskOnClick = () => {
        if (!isMetamaskInstalled()) {
            setDisabled(true);
            const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
            onboarding.startOnboarding();
        } else {
            const walletResponse = connectWallet();
        }
    }
    useEffect(() => {
        const getCurrentWallet = async () => {
            try {
                const { ethereum } = window as any;
                const addressArray = await ethereum.request({ method: "eth_accounts"});
                if (addressArray.length > 0) {
                    setAccount(addressArray[0])
                }
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentWallet();
    }, [])
    return (
        <Button variant="secondary"onClick={() => metamaskOnClick()} disabled={disabled}>
            {metamaskButtonText()}
        </Button>
    )
}