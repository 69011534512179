
import { TIMEOUT } from 'dns';
import React, { useEffect, useState, useRef } from 'react';
import { Nav, Navbar, Container, Row, Overlay } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';


const hints = [
    "LULS",
    "WHAT'S A ROADMAP",

]

const roadmapLinks = [
    "https://rugs.com",
    "https://ruggable.com",
    "https://rugstoreusa.com",
    "https://rugsusa.com",
    "https://rugs-direct.com",
    "https://rugs.com",
    "https://westelm.com",
    "https://wayfair.com",
]

const daoLinks = [
    "https://coinbase.com",
    "https://binance.com",
    "https://ftx.us/trade/ETH/USD"
]


const inspirationLink= ""

const randomHint = () => {
    return hints[Math.floor(Math.random() * hints.length)]
}

const pickRandom = (array: any) => {
    const randoNumber = Math.floor(Math.random() * (array.length))
    return array[randoNumber];
}

export const NavigationBar = (props: any) => {
    const {web3, app} = props
    const [showNftOverlay, setShowNftOverlay] = useState(false);
    const [showOsOverlay, setShowOsOverlay] = useState(false);
    const [nftId, setNftId] = useState(null);
    const [osId, setOsId] = useState(null);
    const [hint, setHint] = useState(randomHint());
    const targetNft = useRef(null);
    const targetOs = useRef(null);
    const fadeOverlay = (setShow: any, show: boolean, id: any, setId: any) =>  {
        if (show) {
            clearTimeout(id);
            setShow(false);
            return;
        } else {
            setHint(randomHint());
            setShow(true);
            const timeId = setTimeout(()=> {
                setShow(false);
            }, 5000);
            setId(timeId);
        }
    }
    return (
    <Navbar expand="lg" variant="dark">
        <Container>
            <Navbar.Brand href="/" className="mx-auto">
                <h2>THIS_IS_NOT_A_RUG</h2>
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav align-items-center" style={{paddingLeft: "5vh"}}>
                <Nav className="mx-auto fs-5">
                    {/* <Nav.Item className="px-x" ref={targetNft} onClick={()=> fadeOverlay(setShowNftOverlay, showNftOverlay, nftId, setNftId)}><Nav.Link>ROADMAP</Nav.Link></Nav.Item>
                    <Overlay target={targetNft.current} show={showNftOverlay} placement="bottom">
                        {({ placement, arrowProps, show: _showNftOverlay, popper, ...props }) => (
                            <div className="fadeIn fade-in-text align-items-center"
                                {...props}
                                style={{
                                padding: '200px 20px',
                                color: 'white',
                                ...props.style,
                                }}
                            >{hint}</div>
                        )} 
                    </Overlay>  */}
                    <Nav.Item className="px-2"><Nav.Link href={pickRandom(roadmapLinks)} target="_blank">Roadmap</Nav.Link></Nav.Item>
                    <Nav.Item className="px-2"><Nav.Link href={pickRandom(daoLinks)} target="_blank">DAO</Nav.Link></Nav.Item>
                    <Nav.Item className="px-2"><Nav.Link href="https://en.wikipedia.org/wiki/The_Treachery_of_Images" target="_blank">Inspiration</Nav.Link></Nav.Item>
                </Nav>
                <Nav className="mx-auto fs-5">
                    <Nav.Item className="px-2">
                        <a href="https://twitter.com/this_is_a_rug" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </Nav.Item>
                    <Nav.Item className="px-2">
                        <a href="https://discord.gg/f7RQpSdJx2" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-discord"></i>
                        </a>
                    </Nav.Item>
                    {/* <Nav.Item className="px-2">
                        <a href="https://opensea.io/collection/cyberbabies" target="_blank" rel="noopener noreferrer">
                            <img className="bi" src="/opensea.svg" height="20px" width="20px"></img>
                        </a>
                    </Nav.Item> */}
                </Nav>
            </Navbar.Collapse>

            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </Container>
    </Navbar>
    )
}
